import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import {
  updateProfile,
  getUserProfile,
  uploadProfileImage,
  fetchProfileImage,
  changePassword,
  deleteUserAccount,
  logout,
} from '../../features/auth/authSlice'
import Spinner from '../../components/Spinner'
import ConfirmDeleteModal from './ConfirmDeleteModal';

const ProfileSettings = () => {
  const { user, isLoading, profileImageUrl } = useSelector(
    (state) => state.auth
  )
  const dispatch = useDispatch()

  const [editMode, setEditMode] = useState(false)
  const [resetPasswordMode, setResetPasswordMode] = useState(false)
  const [formData, setFormData] = useState({
    name: user.name,
    dob: user.dob,
    phoneNumber: user.phoneNumber,
    company: user.company,
  })
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getUserProfile())
  }, [dispatch])

  // State for validation messages
  const [passwordValidation, setPasswordValidation] = useState({
    newPassword: '',
    confirmPassword: '',
  })

  // State to track input field focus
  const [inputFieldsFocus, setInputFieldsFocus] = useState({
    newPassword: false,
    confirmPassword: false,
  })

  const handleImageUpload = async (e) => {
    const file = e.target.files[0]
    if (file) {
      await dispatch(uploadProfileImage(file))
      dispatch(fetchProfileImage())
    }
  }

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(updateProfile(formData))
    setEditMode(false)
  }

  const onCancel = () => {
    setEditMode(false)
  }

  const onPasswordChange = (e) => {
    setPasswordData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))

    // Reset password validation messages when the user types
    setPasswordValidation({
      newPassword: '',
      confirmPassword: '',
    })
  }

  const isValidNewPassword = (password) => {
    const minLength = 8
    const hasDigit = /\d/.test(password)
    const hasUpperCase = /[A-Z]/.test(password)
    return password.length >= minLength && hasDigit && hasUpperCase
  }

  const clearPasswordFields = () => {
    setPasswordData({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    // Clear validation messages
    setPasswordValidation({
      newPassword: '',
      confirmPassword: '',
    })
  }

  const onPasswordSubmit = async (e) => {
    e.preventDefault()

    // Reset previous validation messages
    setPasswordValidation({
      newPassword: '',
      confirmPassword: '',
    })

    if (!isValidNewPassword(passwordData.newPassword)) {
      setPasswordValidation((prevState) => ({
        ...prevState,
        newPassword:
          'New password does not meet the required strength criteria',
      }))
      return
    }
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setPasswordValidation((prevState) => ({
        ...prevState,
        confirmPassword: 'New password and confirm password do not match!',
      }))
      return
    }

    // Assuming changePassword action returns a promise
    try {
      await dispatch(
        changePassword({
          currentPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword,
        })
      ).unwrap()
      toast.success('Password successfully updated')
      setResetPasswordMode(false)
      // Clear password fields after successful submission
      clearPasswordFields()
    } catch (error) {
      toast.error(error.message || 'Failed to change password')
    }
  }

  const handleInputFocus = (fieldName) => {
    setInputFieldsFocus((prevState) => ({
      ...prevState,
      [fieldName]: true,
    }))
  }

  const handleInputBlur = (fieldName) => {
    setInputFieldsFocus((prevState) => ({
      ...prevState,
      [fieldName]: false,
    }))
  }


  const handleDeleteAccount = async () => {
    try {
      await dispatch(deleteUserAccount()).unwrap();
      toast.success('Your account has been marked for deletion. You will be logged out now.');

      setTimeout(async () => {
        await dispatch(logout());
        window.location.href = '/';
      }, 5000); // 5-second delay
    } catch (error) {
      toast.error(error.message || 'Failed to delete account.');
    }
  };

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='container mx-auto p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg'>
      <h1 className='text-2xl font-bold text-gray-800 dark:text-gray-200 mb-5'>
        Profile Settings
      </h1>

      <div className='flex flex-col md:flex-row items-center mb-6'>
        <img
          src={
            profileImageUrl ||
            'https://cdn-icons-png.flaticon.com/512/9131/9131529.png'
          }
          alt='Profile'
          className='rounded-full h-24 w-24 mb-4 md:mb-0 md:mr-4 object-cover shadow-lg hover:shadow-2xl'
        />
        <label className='bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded-2xl cursor-pointer text-xs shadow-md'>
          Upload Profile Picture
          <input type='file' onChange={handleImageUpload} hidden />
        </label>
      </div>

      {editMode ? (
        <form onSubmit={onSubmit}>
          <div className='mb-4'>
            <label
              htmlFor='name'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Name
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={onChange}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='mb-4'>
            <label
              htmlFor='dob'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Date of Birth
            </label>
            <input
              type='date'
              id='dob'
              name='dob'
              value={formData.dob}
              onChange={onChange}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='mb-4'>
            <label
              htmlFor='phoneNumber'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Phone Number
            </label>
            <input
              type='text'
              id='phoneNumber'
              name='phoneNumber'
              value={formData.phoneNumber}
              onChange={onChange}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='mb-4'>
            <label
              htmlFor='company'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Company
            </label>
            <input
              type='text'
              id='company'
              name='company'
              value={formData.company}
              onChange={onChange}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='flex items-center justify-between'>
            <button
              type='submit'
              className='bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            >
              Save Changes
            </button>
            <button
              type='button'
              onClick={onCancel}
              className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            >
              Discard Changes
            </button>
          </div>
        </form>
      ) : (
        <div>
          <p className='text-gray-700 dark:text-gray-300'>
            <strong>Name:</strong> {user.name}
          </p>
          <p className='text-gray-700 dark:text-gray-300'>
            <strong>Date of Birth:</strong> {user.dob.split('T')[0]}
          </p>
          <p className='text-gray-700 dark:text-gray-300'>
            <strong>Phone Number:</strong> {user.phoneNumber}
          </p>
          <p className='text-gray-700 dark:text-gray-300'>
            <strong>Company:</strong> {user.company}
          </p>
          {!editMode && !resetPasswordMode && (
            <div>
              <button
                onClick={() => setEditMode(true)}
                className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-6 mb-4 shadow-md"
              >
                Update Information
              </button>
              <button
                onClick={() => setResetPasswordMode(true)}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4 ml-3"
              >
                Reset Password
              </button>
              <button
                onClick={() => setIsDeleteModalOpen(true)}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4 ml-3"
              >
                Delete Account
              </button>
            </div>
          )}
        </div>
      )}

      {resetPasswordMode && (
        <form onSubmit={onPasswordSubmit} className='mt-4'>
          <div className='mb-4'>
            <label
              htmlFor='currentPassword'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Current Password
            </label>
            <input
              type='password'
              id='currentPassword'
              name='currentPassword'
              value={passwordData.currentPassword}
              onChange={onPasswordChange}
              onFocus={() => handleInputFocus('currentPassword')}
              onBlur={() => handleInputBlur('currentPassword')}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              required
            />
          </div>

          <div className='mb-4'>
            <label
              htmlFor='newPassword'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              New Password
            </label>
            <input
              type='password'
              id='newPassword'
              name='newPassword'
              value={passwordData.newPassword}
              onChange={onPasswordChange}
              onFocus={() => handleInputFocus('newPassword')}
              onBlur={() => handleInputBlur('newPassword')}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              required
            />
            {(inputFieldsFocus.newPassword ||
              passwordValidation.newPassword) && (
                <p
                  className={`text-${passwordValidation.newPassword ? 'red' : 'gray'}-500 text-xs mt-1`}
                >
                  {passwordValidation.newPassword || 'Enter a new password'}
                </p>
              )}
          </div>

          <div className='mb-4'>
            <label
              htmlFor='confirmPassword'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Confirm New Password
            </label>
            <input
              type='password'
              id='confirmPassword'
              name='confirmPassword'
              value={passwordData.confirmPassword}
              onChange={onPasswordChange}
              onFocus={() => handleInputFocus('confirmPassword')}
              onBlur={() => handleInputBlur('confirmPassword')}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              required
            />
            {(inputFieldsFocus.confirmPassword ||
              passwordValidation.confirmPassword) && (
                <p
                  className={`text-${passwordValidation.confirmPassword ? 'red' : 'gray'}-500 text-xs mt-1`}
                >
                  {passwordValidation.confirmPassword ||
                    'Confirm your new password'}
                </p>
              )}
          </div>

          <div className='flex items-center justify-between'>
            <button
              type='submit'
              className='bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            >
              Change Password
            </button>
            <button
              type='button'
              onClick={() => {
                setResetPasswordMode(false)
                clearPasswordFields()
              }}
              className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => {
          setIsDeleteModalOpen(false);
          handleDeleteAccount();
        }}
      />
    </div>
  )
}

export default ProfileSettings
