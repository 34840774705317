// Products.jsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProductsActions from '../../components/Dashboard/Ecommerce/ProductActions';
import ProductFormModal from '../../components/Dashboard/Ecommerce/ProductFormModal';
import DeleteProductModal from '../../components/Dashboard/Ecommerce/DeleteProductModal';
import EditPhotographyModal from '../../components/Dashboard/Photography/EditPhotographyModal';
import ProductsTable from '../../components/Dashboard/Ecommerce/ProductsTable';
import ProductsDataCard from '../../components/Dashboard/Ecommerce/ProductsDataCard';
import {
  getInventoryItems,
  getMoreInventoryItems,
  removeInventoryItem,
} from '../../features/inventory/inventorySlice';
import Spinner from '../../components/Spinner';

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items, loading, error, hasMoreItems } = useSelector(
    (state) => state.inventory
  );
  const [showProductFormModal, setShowProductFormModal] = useState(false);
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [showEditPhotographyModal, setShowEditPhotographyModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cardView, setCardView] = useState(true);

  const observer = useRef();

  useEffect(() => {
    dispatch(getInventoryItems());
  }, [dispatch]);

  // Debugging: Log the loading, items, and error state
  useEffect(() => {
    console.log('Loading:', loading);
    console.log('Items:', items);
    console.log('Error:', error);
  }, [loading, items, error]);

  // Handle errors and redirect appropriately
  useEffect(() => {
    if (!loading) {
      if (error) {
        console.error('Error fetching inventory items:', error);
        // Optionally, navigate to an error page or display a message
        // navigate('/error');
      } else if (items && items.length === 0) {
        navigate('/arbie/onboarding');
      }
    }
  }, [loading, error, items, navigate]);

  const handleAddButtonClick = () => {
    navigate('/arbie/products/'); // Navigate to ProductDetailsPage to add a new product
  };

  const handleEditButtonClick = (product) => {
    setSelectedProduct(product);
    setShowProductFormModal(true);
  };

  const handleDeleteButtonClick = (product) => {
    setSelectedProduct(product);
    setShowDeleteProductModal(true);
  };

  const handleEditPhotosButtonClick = (product) => {
    setSelectedProduct(product);
    setShowEditPhotographyModal(true);
  };

  const handleProductFormModalClose = () => {
    setShowProductFormModal(false);
  };

  const handleDeleteProductModalClose = () => {
    setShowDeleteProductModal(false);
  };

  const handleEditPhotographyModalClose = () => {
    setShowEditPhotographyModal(false);
  };

  const handleProductDeleteConfirm = () => {
    if (selectedProduct) {
      dispatch(removeInventoryItem(selectedProduct._id));
      setShowDeleteProductModal(false);
    }
  };

  const loadMoreItems = useCallback(() => {
    if (hasMoreItems) {
      dispatch(getMoreInventoryItems());
    }
  }, [dispatch, hasMoreItems]);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      },
      options
    );
    const lastItem = document.querySelector('.last-item');
    if (lastItem) observer.current.observe(lastItem);
  }, [items.length, loadMoreItems]);

  const handleCardClick = (productId) => {
    navigate(`/arbie/products/${productId}`); // Navigate to ProductDetailsPage with product ID
  };

  // Adjusted loading and error handling
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-red-500">Error: {error}</p>
      </div>
    );
  }

  if (!loading && items && items.length === 0) {
    // Return null while the redirection happens
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col p-4 pt-0">
      <div className="container min-w-full mx-auto">
        <>
          <div className="sticky top-0 z-20 bg-gray-50 dark:bg-gray-900">
            <ProductsActions
              onAddProduct={handleAddButtonClick}
              toggleView={() => setCardView(!cardView)}
              cardView={cardView}
            />
          </div>

          {error && <div className="text-center text-red-500">{error}</div>}

          <div className="overflow-y-auto mt-2">
            {cardView ? (
              <ProductsDataCard
                items={items}
                onEdit={handleEditButtonClick}
                onDelete={handleDeleteButtonClick}
                onEditPhotos={handleEditPhotosButtonClick}
                onCardClick={handleCardClick}
              />
            ) : (
              <ProductsTable
                items={items}
                onEdit={handleEditButtonClick}
                onDelete={handleDeleteButtonClick}
                onEditPhotos={handleEditPhotosButtonClick}
              />
            )}
          </div>

          {loading && items.length > 0 && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}

          {showProductFormModal && (
            <ProductFormModal
              isOpen={showProductFormModal}
              onClose={handleProductFormModalClose}
              product={selectedProduct}
              onSuccess={() => dispatch(getInventoryItems())}
            />
          )}

          {showDeleteProductModal && (
            <DeleteProductModal
              isOpen={showDeleteProductModal}
              onClose={handleDeleteProductModalClose}
              onConfirm={handleProductDeleteConfirm}
              product={selectedProduct}
            />
          )}

          {showEditPhotographyModal && (
            <EditPhotographyModal
              isOpen={showEditPhotographyModal}
              onClose={handleEditPhotographyModalClose}
              item={selectedProduct}
            />
          )}

          <div className="last-item"></div>
        </>
      </div>
    </div>
  );
};

export default Products;
