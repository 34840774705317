// SyncInventoryModal.jsx

import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';

const SyncInventoryModal = ({ isOpen, onClose, onConfirm, connections }) => {
  const navigate = useNavigate();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-80 transition-opacity" />
          </Transition.Child>

          {/* Centering Trick */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          {/* Modal Content */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-6 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border dark:border-gray-700">
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
                <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">
                  Select Account to Sync Inventory
                </h2>
                <p className="mb-4 text-gray-700 dark:text-gray-300">
                  Please select the account you want to sync the inventory with.
                </p>
                <div className="space-y-2">
                  {connections && connections.length > 0 ? (
                    connections.map((connection) => (
                      <button
                        key={connection.id}
                        onClick={() => onConfirm(connection)}
                        className="w-full bg-indigo-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-indigo-700 dark:hover:bg-indigo-500 transition duration-300 ease-in-out"
                      >
                        {connection.accountName}
                      </button>
                    ))
                  ) : (
                    <p className="text-gray-700 dark:text-gray-300">
                      No connected accounts available.
                    </p>
                  )}
                </div>

                {/* Option to Add New Connection */}
                <button
                  onClick={() => navigate('/settings/connections')}
                  className="mt-4 w-full flex items-center justify-center bg-gray-200 dark:bg-gray-700 text-indigo-600 dark:text-indigo-400 font-medium py-2 px-4 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300 ease-in-out"
                >
                  <PlusCircleIcon className="h-5 w-5 mr-2" />
                  Add New Account
                </button>

                <div className="flex justify-end mt-4">
                  <button
                    type="button"
                    className="bg-gray-500 dark:bg-gray-600 text-white font-semibold py-2 px-4 rounded-full mr-2 hover:bg-gray-600 dark:hover:bg-gray-500 transition duration-300"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SyncInventoryModal;
