import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/api/inventory`;
const photographyUrl = `${apiUrl}/api/photography`

// **Existing Functions**

// Fetch all inventory items
export const fetchInventoryItems = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(baseUrl, config);
  return data;
};

// Fetch a single inventory item by ID
export const fetchInventoryItemById = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(`${baseUrl}/${id}`, config);
  return data;
};

// Create a new inventory item
export const createInventoryItem = async (token, inventoryData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.post(baseUrl, inventoryData, config);
  return data;
};

// Update an existing inventory item
export const updateInventoryItem = async (token, id, inventoryData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.put(`${baseUrl}/${id}`, inventoryData, config);
  return data;
};

// Delete an inventory item
export const deleteInventoryItem = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios.delete(`${baseUrl}/${id}`, config);
};

// Fetch inventory items with photographyStatus 'pending'
export const fetchPendingPhotographyItems = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(`${baseUrl}/photography/pending`, config);
  return data;
};

// Function to upload images for a specific inventory item
export const uploadPhotographyImages = async (token, inventoryItemId, formData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  const { data } = await axios.post(
    `${photographyUrl}/upload-images/${inventoryItemId}`,
    formData,
    config
  );
  return data;
};

export const syncInventoryItems = async (token, id, platform, user, countOnly = false) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    id,
    platform,
    user,
    countOnly, // Pass countOnly to the backend
  };

  const { data } = await axios.post(`${baseUrl}/sync`, body, config);
  return data;
};

// **New Functions for SKU Prefix and Custom Fields Management**

/**
 * Fetch the current SKU prefix configuration for the authenticated user.
 * @param {string} token - Authentication token.
 * @returns {Object} - Contains the current SKU prefix.
 */
export const getSkuPrefix = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(`${baseUrl}/settings/sku-prefix`, config);
  return data;
};

/**
 * Update the SKU prefix configuration for the authenticated user.
 * @param {string} token - Authentication token.
 * @param {string} newPrefix - The new SKU prefix to set.
 * @returns {Object} - Contains the updated SKU prefix.
 */
export const updateSkuPrefix = async (token, newPrefix) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const payload = { skuPrefix: newPrefix };

  const { data } = await axios.put(`${baseUrl}/settings/sku-prefix`, payload, config);
  return data;
};

/**
 * Fetch default custom fields and preferences for the authenticated user.
 * @param {string} token - Authentication token.
 * @returns {Object} - Contains the default custom fields and preferences.
 */
export const getDefaultCustomFields = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.get(`${baseUrl}/settings/customFields`, config);
  return data;
};

/**
 * Update default custom fields and preferences for the authenticated user.
 * @param {string} token - Authentication token.
 * @param {Object} customFieldSettings - The updated custom fields and preferences.
 * @returns {Object} - Contains the updated custom fields and preferences.
 */
export const updateDefaultCustomFields = async (token, customFieldSettings) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const { data } = await axios.put(`${baseUrl}/settings/customFields`, customFieldSettings, config);
  return data;
};

/**
 * Delete a specific custom field by its ID.
 * @param {string} token - Authentication token.
 * @param {string} fieldId - The ID of the custom field to delete.
 * @returns {Object} - Contains the status message or success response.
 */
export const deleteCustomField = async (token, fieldId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.delete(`${baseUrl}/settings/customFields/${fieldId}`, config);
  return data;
};

export default {
  fetchInventoryItems,
  fetchInventoryItemById,
  createInventoryItem,
  updateInventoryItem,
  deleteInventoryItem,
  fetchPendingPhotographyItems,
  uploadPhotographyImages,
  getDefaultCustomFields,
  updateDefaultCustomFields,
  getSkuPrefix,
  updateSkuPrefix,
  syncInventoryItems,
  deleteCustomField, 
};
