import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL

const API_URL = `${apiUrl}/api/users/`

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData)
  return response.data
}

// Verify Unregistered user
const verifyUser = async (userData) => {
  const response = await axios.post(API_URL + 'verifyUser', userData)
  return response.data
}

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData)
  return response.data
}

// Refresh token
const refreshToken = async (refreshToken, encryptedPassword) => {
  const response = await axios.post(`${API_URL}refresh-token`, {
    token: refreshToken,
    encryptedPassword,
  });
  return response.data;
};

// Logout user
const logout = async (refreshToken) => {
  const config = {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  }

  const response = await axios.post(`${API_URL}logout`, {}, config)
  return response.data
}

// Forgot Password
const forgotPassword = async (userData) => {
  const response = await axios.post(`${API_URL}forgotPassword`, userData)
  return response.data
}

// Confirm Forgot Password
const forgotPasswordConfirm = async (userData) => {
  const response = await axios.post(
    `${API_URL}forgot-password-confirm`,
    userData
  )
  return response.data
}

// Update user profile
const updateProfile = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.put(API_URL + 'profile', userData, config)
  return response.data
}

// Get user profile
const getUserProfile = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + 'me', config)
  return response.data
}

// Function to upload profile image
const uploadProfileImage = async (imageData, token) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }

  const formData = new FormData()
  formData.append('image', imageData)

  const response = await axios.post(`${API_URL}profile/image`, formData, config)
  return response.data
}

// Function to fetch profile image URL
const fetchProfileImage = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(`${API_URL}profile/image`, config)
  return response.data
}

// Change password
const changePassword = async (passwordData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(
    `${API_URL}change-password`,
    passwordData,
    config
  )
  return response.data
}

// Subscribe to newsletter
const subscribeNewsletter = async (newsletterData) => {
  const response = await axios.post(`${API_URL}subscribe-newsletter`, newsletterData)
  return response.data
}

// Register bypass
const registerBypass = async (password) => {
  const response = await axios.post(`${API_URL}register-bypass`, { password })
  return response.data
}


const deleteUserAccount = async (user, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + 'delete-account', {
    data: { user }, // Include the user in the request body
    ...config,
  });
  return response.data;
};

const authService = {
  register,
  login,
  refreshToken,
  logout,
  updateProfile,
  getUserProfile,
  uploadProfileImage,
  fetchProfileImage,
  changePassword,
  verifyUser,
  forgotPassword,
  forgotPasswordConfirm,
  subscribeNewsletter,
  registerBypass,
  deleteUserAccount,
}

export default authService
