import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ChartBarIcon,
  SearchIcon,
  CubeIcon,
  PhotographIcon,
  ShoppingCartIcon,
  ClipboardCheckIcon,
  ViewGridIcon,
  QuestionMarkCircleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  XIcon,
} from '@heroicons/react/outline';
import Logo from '../../images/arbie_gray_logo.png';

const DashSidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  isSidebarCollapsed,
  setIsSidebarCollapsed,
}) => {
  const { user, profileImageUrl } = useSelector((state) => state.auth);

  const sections = [
    {
      title: 'Overview',
      items: [
        {
          name: 'Dashboard',
          link: '/arbie',
          icon: (
            <ViewGridIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
      ],
    },
    {
      title: 'Product Management',
      items: [
        {
          name: 'Product Research',
          link: '/arbie/research',
          icon: (
            <SearchIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
        {
          name: 'Results Feed',
          link: '/arbie/results-feed',
          icon: (
            <ChartBarIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
        {
          name: 'Inbound Inventory',
          link: '/arbie/purchases',
          icon: (
            <ShoppingCartIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
        {
          name: 'Photography Queue',
          link: '/arbie/photo-queue',
          icon: (
            <PhotographIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
        {
          name: 'Inventory Management',
          link: '/arbie/ecom-products',
          icon: (
            <CubeIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
      ],
    },
    {
      title: 'Sales & Orders',
      items: [
        {
          name: 'Order Fulfilment',
          link: '/arbie/orders',
          icon: (
            <ClipboardCheckIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
      ],
    },
  ];

  return (
    <div
      className={`fixed inset-0 z-50 transition-transform transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 lg:static lg:z-auto lg:inset-auto bg-white dark:bg-gray-800 border-r shadow-lg h-full ${isSidebarCollapsed ? 'lg:w-16' : 'lg:w-64'
        } w-72 flex flex-col justify-between dark:border-gray-700`}
    >
      <div className='flex flex-col h-full'>
        {/* Top Section: Logo and Close Button for Mobile */}
        <div className='flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 lg:hidden'>
          <NavLink to='/' className='flex items-center'>
            <img src={Logo} className='h-8 w-auto' alt='Logo' />
          </NavLink>
          <button
            className='text-gray-500 dark:text-gray-400'
            onClick={() => setIsSidebarOpen(false)}
          >
            <XIcon className='w-6 h-6' />
          </button>
        </div>

        {/* Sidebar Content */}
        <div className='flex-grow overflow-y-auto'>
          {/* Expanded Sidebar Content: Always visible on mobile, conditional on desktop */}
          <div className={`${isSidebarCollapsed ? 'lg:hidden' : 'block'}`}>
            {/* User Info */}
            <div className='p-4'>
              <div className='flex items-center mb-4'>
                <img
                  src={
                    profileImageUrl ||
                    'https://cdn-icons-png.flaticon.com/512/9131/9131529.png'
                  }
                  className='h-10 w-10 rounded-full border-2 border-gray-300 dark:border-gray-600'
                  alt='User Avatar'
                />
                {/* User Name: Hidden on Desktop if Collapsed */}
                <div className={`${isSidebarCollapsed ? 'lg:hidden' : 'ml-3'}`}>
                  <h2 className='text-sm font-semibold text-gray-700 dark:text-gray-200'>
                    Welcome, {user?.name}
                  </h2>
                </div>
              </div>

              {/* Navigation Sections */}
              {sections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <h3 className='text-xs font-semibold uppercase tracking-wider text-gray-500 dark:text-gray-400 mb-2'>
                    {section.title}
                  </h3>
                  <nav className='space-y-1 mb-4'>
                    {section.items.map((item, index) => (
                      <NavLink
                        key={index}
                        to={item.link}
                        className='flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 transition'
                        activeClassName='bg-gray-200 dark:bg-gray-700'
                        onClick={() => {
                          setIsSidebarOpen(false);
                        }}
                      >
                        {item.icon}
                        {/* Item Name: Hidden on Desktop if Collapsed */}
                        <span
                          className={`ml-3 ${isSidebarCollapsed ? 'lg:hidden' : 'inline'
                            }`}
                        >
                          {item.name}
                        </span>
                      </NavLink>
                    ))}
                  </nav>
                </div>
              ))}
            </div>

            {/* Quick Metrics: Hidden on Desktop if Collapsed */}
            <div
              className={`p-4 pt-2 pb-2 bg-gray-100 dark:bg-gray-900 rounded-lg m-4 mb-0 mt-0 border dark:border-gray-700 ${isSidebarCollapsed ? 'lg:hidden' : ''
                }`}
            >
              <div className='mb-2'>
                <h5 className='text-sm font-semibold text-gray-700 dark:text-gray-200'>
                  Quick Metrics
                </h5>
              </div>
              <div className='mb-3 flex items-center'>
                <ChartBarIcon className='w-5 h-5 text-indigo-500 dark:text-indigo-400' />
                <div className='ml-4'>
                  <h5 className='text-xs font-semibold text-gray-700 dark:text-gray-200'>
                    Results to Review
                  </h5>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>25</p>
                </div>
              </div>
              <div className='mb-3 flex items-center'>
                <ClipboardCheckIcon className='w-5 h-5 text-indigo-500 dark:text-indigo-400' />
                <div className='ml-4'>
                  <h5 className='text-xs font-semibold text-gray-700 dark:text-gray-200'>
                    Pending Orders
                  </h5>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>12</p>
                </div>
              </div>
              <div className='flex items-center'>
                <PhotographIcon className='w-5 h-5 text-indigo-500 dark:text-indigo-400' />
                <div className='ml-4'>
                  <h5 className='text-xs font-semibold text-gray-700 dark:text-gray-200'>
                    Photography Queue
                  </h5>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>8</p>
                </div>
              </div>
            </div>

            {/* Need Help Section: Hidden on Desktop if Collapsed */}
            <div
              className={`p-4 bg-gray-100 dark:bg-gray-900 rounded-lg m-4 border dark:border-gray-700 ${isSidebarCollapsed ? 'lg:hidden' : ''
                }`}
            >
              <div className='flex items-center'>
                <QuestionMarkCircleIcon className='w-6 h-6 text-indigo-500 dark:text-indigo-400 flex-shrink-0' />
                <div className='ml-3'>
                  <h4 className='text-sm font-semibold text-gray-700 dark:text-gray-200'>
                    Need Help?
                  </h4>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>
                    Submit a ticket and our team will assist you.
                  </p>
                </div>
              </div>

              <NavLink
                to='/settings/help'
                className='block mt-4 text-center text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-400 rounded-md py-2'
              >
                Submit a Ticket
              </NavLink>
            </div>
          </div>

          {/* Sidebar Collapsed View on Desktop */}
          {isSidebarCollapsed && (
            <nav className='flex-col items-center p-4 space-y-2 lg:flex hidden'>
              {sections.map((section) =>
                section.items.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.link}
                    className='flex items-center justify-center p-4 text-gray-700 hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 rounded-lg transition'
                    activeClassName='bg-gray-200 dark:bg-gray-700'
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                  >
                    {React.cloneElement(item.icon, {
                      className: 'w-6 h-6 text-indigo-500',
                    })}
                  </NavLink>
                ))
              )}
            </nav>
          )}
        </div>

        {/* Bottom Section: Collapse/Expand Button for Large Screens */}
        <div className='p-4 hidden lg:flex'>
          {isSidebarCollapsed ? (
            <button
              className='flex items-center justify-center w-full h-10 bg-gray-200 dark:bg-gray-700 rounded-md'
              onClick={() => setIsSidebarCollapsed(false)}
            >
              <ArrowRightIcon className='w-6 h-6 text-gray-500 dark:text-gray-400' />
            </button>
          ) : (
            <button
              className='flex items-center justify-center w-full h-10 bg-gray-200 dark:bg-gray-700 rounded-md'
              onClick={() => setIsSidebarCollapsed(true)}
            >
              <ArrowLeftIcon className='w-6 h-6 text-gray-500 dark:text-gray-400' />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashSidebar;
